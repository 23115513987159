/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-case-declarations */

// Render Copy content type (usually within panel)
//
// Usage:  <Copy data={ object returned from contentstack }/>
// Result: renders copy (text)
//
// Assumes this structure:
//     data.copy   copy(text) to be rendered, in markdown format
//     data.styles array of one or more modular block style elements.
//
// example input:
//   {
//     copy: "#### Our Promise to You ####",
//     styles: [
//         {alignment: {text_align: "Left"}},
//         {font_size: {font_size: 1.25}},
//         {color: {
//             copy_color: {colorTheme: "custom", color: "#2f2f2f"},
//             background_color: {colorTheme: "none"},
//         }},
//     ]
//   }

import React, { Component } from 'react';
import {
    array, string, shape, object, bool, func,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
// import { gql } from '@apollo/client';
// import { Query } from '@apollo/client/react/components';
import Caret from '@material-ui/icons/ArrowForwardIos';
import { bindActionCreators } from 'redux';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import generateValidCss from '../../helpers/contentstack/generateValidCss';
import parseMarkdown from '../../helpers/contentstack/parseMarkdown';
import openInNewTab from '../../helpers/contentstack/openInNewTab';
import { getSSRDeviceType } from '../../../state/ducks/App/App-Selectors';
import { trackEvent } from '../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getPresentationFamily } from '../../../state/ducks/App/ducks/Config/Config-Selectors';
import SVGWithPosition from './SVGWithPosition';

// import { GRAPHQL_ENV } from '../../gql';
import PROMO_QUERY from './PromoQuery';

const useStyles = () => ({
    dialogContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flex: '0 0 auto',
        '& .MuiDialogTitle-root': {
            '@media only screen and (max-width: 440px) ': {
                padding: '16px 0px 16px 24px',
            },
        },
        '& .MuiTypography-root': {
            '@media only screen and (max-width: 440px) ': {
                fontSize: '1em',
            },
        },
    },
    detailsLink: {
        '&:focus': {
            outline: 'none',
        },
        zIndex: 1,
        '& p': {
            margin: 0,
        },
        '& div': {
            display: 'inline-block',
        },
    },
    closeButton: {
        padding: '16px 24px',
        '@media only screen and (max-width: 440px) ': {
            padding: '16px 10px',
        },
        '& svg': {
            '@media only screen and (max-width: 440px) ': {
                width: '22px',
            },
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiTouchRipple-root span': {
            display: 'none !important',
        },
    },
    caretFilled: {
        '& *:first-child': {
            margin: 0,
            fontSize: 34,
        },
    },
    ctaButton: {
        '& .MuiButton-endIcon': {
            margin: 0,
        },
        '& .MuiTouchRipple-child': {
            background: 'transparent',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    ctaIconFilled: {
        '&:first-child': {
            margin: 0,
            fontSize: 34,
        },
    },
    noTextDecoration: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    textUnderlineHover: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    textUnderline: {
        textDecoration: 'underline',
    },
    seeDetailsLink: {
        marginLeft: '5px',
        cursor: 'pointer',
    },
    ctaContainer: {
        display: 'inline-block',
        margin: '0px 15px',
    },
});

class Copy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    // TODO:: We can move this to brand config.
    getCaretIcon = ({ icon_type = null, add_icon = null }, style) => {
        const { classes } = this.props;
        /*
        4810; ensure entries in cms have 'Add Icon' field properly filled so this hardcoded default can be fully removed
        if ((!icon_type || icon_type === 'none') && !add_icon) {
            // return <Caret style={style}>content</Caret>; // Returning default for other entries to avoid any issue in current published content
        }
        */
        if (!add_icon) {
            return null;
        }
        switch (icon_type?.toLowerCase()) {
            case 'solid triangle':
                return <ArrowRightIcon className={classes.ctaIconFilled} />;
            case 'caret':
                return <Caret style={style}>content</Caret>;
            case 'chevron':
                return <ChevronRightIcon className={classes.ctaIconFilled} />;
            default:
                return null;
        }
    }

    getCtaCount = (data) => {
        let ctaLength = 0;
        if (data?.length) {
            data.forEach((k) => {
                const type = Object.keys(k)[0];
                switch (type) {
                    case 'cta':
                        ctaLength += 1;
                        break;
                    default:
                        break;
                }
            });
        }
        return ctaLength;
    }

    render() {
        const { open } = this.state;
        const thisComponent = 'Copy';
        const {
            data, detailLinkLabel, link, classes, mobileCopy, ssrDeviceType, track, presentationFamily, svgPositionStyles,
        } = this.props;
        const all = [];
        const iconStyle = {
            width: '.65em',
            height: '.65em',
            fontSize: '20px',
            margin: '3px 0px',
        };
        let icon = null;
        let ctaClassName = '';
        const ctaCount = this.getCtaCount(data);
        const checkDeviceType = ssrDeviceType === 'desktop' || ssrDeviceType === 'tablet';

        const handleModal = (e, windowOpen) => {
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
            e.persist();
            e.stopPropagation();
            this.setState({ open: windowOpen });
        };

        const closeModal = (e) => handleModal(e, false);

        const btnStyle = (contentRoot) => {
            const marginSet = contentRoot.styles.find((block) => block?.margin);
            const styles = contentRoot.styles.length ? generateValidCss(contentRoot.styles, true) : {};
            const marginStyle = contentRoot.styles.find((block) => block?.justify);
            styles.width = styles?.width || 'fit-content';
            styles.display = 'flex';
            styles.fontWeight = '400';
            styles.borderRadius =  presentationFamily === 'flower' ? '4px' : '0';
            styles.padding = '0.2em 1em';
            styles.marginTop = `${marginSet?.margin?.top || 10}px`; // handle from the cms
            styles.marginBottom = `${marginSet?.margin?.bottom || 10}px`; // handle from the cms
            if (contentRoot.pellet_button) {
                styles.borderRadius = '25px';
            }

            /**
             * TODO: Need to refactor and this component will be convert to functional
             */
            if (presentationFamily === 'food') {
                if (marginStyle?.justify?.justify_content?.toLowerCase?.() === 'right') {
                    styles.marginRight = '0';
                    styles.marginLeft = 'auto';
                } else if (marginStyle?.justify?.justify_content?.toLowerCase?.() === 'left') {
                    styles.marginRight = 'auto';
                    styles.marginLeft = '0';
                } else if (marginStyle?.justify?.justify_content?.toLowerCase?.() === 'center') {
                    styles.marginRight = 'auto';
                    styles.marginLeft = 'auto';
                } else {
                    styles.marginRight = 'auto';
                    styles.marginLeft = 'auto';
                }
                /**
                 * This style is required, because the MUI button was converted to div for cta in other ticket.
                 * This need more generic, added for now for fixing the prod layout bug
                 */
                styles.display = 'flex'; // food need flex
                styles.width = 'max-content';
                styles.alignItems = 'center';
                styles.textTransform = 'uppercase';
            }

            if (marginStyle?.justify?.justify_content?.toLowerCase() === 'right' && presentationFamily === 'flower')  {
                styles.marginRight = 'auto';
                styles.marginLeft = '70%';
            }
            if (ssrDeviceType === 'mobile' &&  presentationFamily === 'flower') {
                styles.marginRight = 'auto';
                styles.marginLeft = 'auto';
            }
            return styles;
        };

        const renderModalLink = (keyword, code, linkColor) => ({
            html: ({ value }) => {
                if (value?.indexOf?.(keyword) >= 0) {
                    return (
                        <>
                            <ReactMarkdown source={value?.replace?.(keyword, '')} escapeHtml={false} />
                            <span
                                onClick={(e) => { handleModal(e, true, code); }}
                                role="presentation"
                                className={classes.seeDetailsLink}
                                style={{ color: linkColor }}
                            >
                                <u>{keyword}</u>
                            </span>
                        </>

                    );
                }
                return <ReactMarkdown source={value} escapeHtml={false}  />;
            },
        });

        let hasImageAndImageIsSVG = false;

        if (data?.length) {
            data.forEach((k, i) => {
                const type = Object.keys(k)[0];
                const contentRoot = data[i]?.[type];
                let content = contentRoot?.[type];
                const hasStyles = contentRoot?.styles ? `style="${generateValidCss(contentRoot.styles)}"` : '';

                switch (type) {
                    case 'copy':
                        if (content) {
                            const style =  presentationFamily === 'flower' && ssrDeviceType === 'mobile' ? contentRoot.styles?.filter((b) => !b?.margin && !b?.alignment) : contentRoot.styles;
                            if ((mobileCopy && ssrDeviceType === 'mobile') || checkDeviceType) {
                                all.push(<ReactMarkdown key={k} className="messageCopy" source={parseMarkdown(content, style)} escapeHtml={false} svgPositioningId={`copy-${i}`}  />);
                            }
                        }
                        break;
                    case 'image':
                        if (content?.title?.includes('.svg')) {
                            hasImageAndImageIsSVG = true;
                        }

                        if (content) {
                            content = `<picture>
                                       <source srcset="${content?.url}?auto=webp">
                                       <source srcset="${content?.url}?auto=pjpg">
                                       <img src="${content?.url}?auto=pjpg" ${hasStyles} alt="${content?.description || 'Image Block'}" />
                                       </picture>`;
                            all.push(<ReactMarkdown key={k} source={content} escapeHtml={false} svgPositioningId={`svg-${i}`} />);
                        }
                        break;
                    case 'details_link':
                        const presentation = contentRoot.presentation === 'Single Line' ? '  ' : '<br>';
                        const code = content;
                        const linkLabel = contentRoot.custom_detail_link_copy || detailLinkLabel;
                        const linkColor = contentRoot.styles?.[0]?.color?.color?.color || '';
                        let renderString = `<div style="z-index:1">Use code <strong>${code}</strong>${presentation}${linkLabel}</div>`;
                        if (contentRoot.hide_code) {
                            renderString = `<div style="z-index:1">${presentation}${linkLabel}</div>`;
                        }
                        all.push(
                            <>
                                <div className={classes.detailsLink} key={k}>
                                    <ReactMarkdown source={parseMarkdown(renderString, contentRoot.styles)} escapeHtml={false} renderers={renderModalLink(linkLabel, code, linkColor)}  />
                                </div>
                                { open ? (
                                    <Dialog open={open} onClick={closeModal}>
                                        <div className={classes.dialogContent}>
                                            <DialogTitle id="customized-dialog-title">
                                                Details for: {code}
                                            </DialogTitle>
                                            <IconButton data-testid="close-button" className={classes.closeButton} aria-label="close" onClick={closeModal}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                        <MuiDialogContent dividers>
                                            <Typography gutterBottom>
                                                <PROMO_QUERY promocode={code} />
                                            </Typography>
                                        </MuiDialogContent>
                                    </Dialog>
                                ) : null }
                            </>,
                        );
                        break;
                    case 'cta': // need to handle styles differently - possibly a reference?
                        // hasStyles = `${hasStyles} ${contentRoot.styles.length !== undefined ? generateValidCss(contentRoot.styles) : ''}`;
                        icon = this.getCaretIcon(contentRoot || {}, iconStyle);
                        if (contentRoot?.cta_underline?.toLowerCase() === 'underline') {
                            ctaClassName = classes.textUnderline;
                        } else if (contentRoot?.cta_underline?.toLowerCase() === 'underline on hover') {
                            ctaClassName = classes.textUnderlineHover;
                        } else if (contentRoot?.cta_underline?.toLowerCase() === 'none') { // adding case to avoid the issue in older entries
                            ctaClassName = classes.noTextDecoration;
                        }
                        if (link?.link?.href) {
                            all.push(
                                <Link
                                    key={k}
                                    className={link.style}
                                    to={{ pathname: link.link.href }}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...openInNewTab(link.link.href)}
                                    onClick={() => {
                                        track({
                                            eventCategory: link?.tracking_event_category || '',
                                            eventAction: link?.tracking_event_action || '',
                                            eventLabel: link?.tracking_event_label || '',
                                        });
                                    }}
                                    svgPositioningId={`cta-${i}`}
                                >
                                    {content}
                                </Link>,
                            );
                        } else {
                            all.push(<div className={ctaCount > 1 && classes.ctaContainer} data-testid={ctaCount > 1 && 'multi-cta'}><div key={k} data-testid="copy-cta" className={`${classes.ctaButton} ${ctaClassName}`} style={btnStyle(contentRoot)} svgPositioningId={`cta-${i}`}>{content}{icon || ''}</div></div>);
                        }
                        break;
                    case 'link_list':
                        break; // this is handled outside of this iteration
                    default:
                        mbpLogger.logInfo({ appName: process.env.npm_package_name, component: thisComponent, message: `Unhandled block type: '${type}'` });
                }
            });
        }

        if (hasImageAndImageIsSVG && svgPositionStyles?.position) {
            return (
                <SVGWithPosition data={all} svgPositionStyles={svgPositionStyles} />
            );
        }

        return <>{ all }</>;
    }
}

Copy.propTypes = {
    ssrDeviceType: string.isRequired,
    mobileCopy: bool,
    classes: object.isRequired,
    data: array.isRequired,
    detailLinkLabel: string,
    link: shape({
        style: string,
        link: shape({
            href: string,
        }),
    }),
    track: func,
    presentationFamily: string.isRequired,
    svgPositionStyles: object,
};

Copy.defaultProps = {
    detailLinkLabel: 'See Details',
    link: null,
    mobileCopy: true, // need true by default, so other comps not affecting with this change.
    track: () => {},
    svgPositionStyles: {},
};
const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
    presentationFamily: getPresentationFamily(state),
});

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Copy));
