/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array, object,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    svgPositionWrapper: ({ svgPosition, marginTop }) => ({
        display: 'flex',
        flexDirection: svgPosition,
        marginTop: marginTop || '',
    }),
    svgContainer: {
    },
    copyCTAContainer: ({ showDivider }) => ({
        flex: showDivider ? '0 0 50%' : '',
        margin: 'auto 0',
    }),

    dividerHorizontal: ({ dividerColor }) => ({
        margin: 'auto',
        width: '80%',
        borderBottom: dividerColor ? `1px solid ${dividerColor}` : '1px solid #a5a5a5',
    }),
    dividerVertical: ({ dividerColor }) => ({
        margin: '5% auto',
        borderRight: dividerColor ? `1px solid ${dividerColor}` : '1px solid #a5a5a5',
    }),
}));

const SVGWithPosition = ({ data, svgPositionStyles }) => {
    let isDividerOrientationVertical = true;
    let svgPosition = '';
    if (svgPositionStyles?.position) {
        switch (svgPositionStyles.position) {
            case 'left':
                // svg | copy/cta
                svgPosition = 'row';
                break;
            case 'right':
                // copy/cta | svg
                svgPosition = 'row-reverse';
                break;
            case 'top':
                // svg
                // ---
                // copy/cta
                svgPosition = 'column';
                isDividerOrientationVertical = false;
                break;
            case 'bottom':
                // copy/cta
                // ---
                // svg
                svgPosition = 'column-reverse';
                isDividerOrientationVertical = false;
                break;
            case 'center':
            default:
                // intended for svg only, but since default flex-direction value is 'row' will also cover cases where position should be 'left' from cms but is not filled out.
                break;
        }
    }

    // there is an instructional note in CMS to keep order of 'message_collection' of a 'Panel' in the order of: svg, copy1, copy2, cta. this function will search and assign message blocks array from parent Copy component
    const findChildByMessageType = (children, type) => {
        // there can be multiple copy blocks in 'message_collection' from CMS
        if (type === 'copy') {
            return children.filter((elem) => (elem.props.svgPositioningId.includes(type)));
        }

        return children.find((elem) => (elem.props.svgPositioningId.includes(type)));
    };

    const svgChild = findChildByMessageType(data, 'svg');
    const copyChildren = findChildByMessageType(data, 'copy');
    const ctaChild = findChildByMessageType(data, 'cta');

    const showDivider = ctaChild || copyChildren.length > 0;

    const marginTop = svgPositionStyles?.margin_top;
    const dividerColor = svgPositionStyles?.divider_color?.color;
    const classes = useStyles({
        svgPosition,
        marginTop,
        dividerColor,
        showDivider,
    });

    return (
        <div className={classes.svgPositionWrapper}>
            <div className={classes.svgContainer}>
                {svgChild}
            </div>
            {showDivider && <hr className={isDividerOrientationVertical ? classes.dividerVertical : classes.dividerHorizontal} />}
            <div className={classes.copyCTAContainer}>
                {[...copyChildren, ctaChild]}
            </div>
        </div>
    );
};

SVGWithPosition.propTypes = {
    data: array,
    svgPositionStyles: object,
};

SVGWithPosition.defaultProps = {
    data: [],
    svgPositionStyles: {},
};

export default SVGWithPosition;
