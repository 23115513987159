/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';
import processCss from './generateValidCss';

// Convert simple markdown into elements and add inline styles
// ReactMarkdown will not parse styles

const regRegex = new RegExp('[^>]®');
const tmRegex = new RegExp('[^>]™');

export default function parseMarkdown(markdown, styles) {
    const element = {};
    const parsedMarkdown = {};
    const markdownArr = markdown.split(' ');
    const elID = markdownArr[0];
    let hasStyles = '';
    const identifyElement = () => {
        switch (true) {
            case (elID.includes('#')):
                parsedMarkdown.ele = `h${elID.length}`;
                markdownArr.shift();
                break;
            default:
                parsedMarkdown.ele = 'div';
        }
        return `${parsedMarkdown.ele}`;
    };

    // removing the styling from the div when value is true
    const isStyle = ['<strike>', '<s>', '<del>', '<em>', '<delete>', '<i>'].some((check) => elID?.startsWith(check));

    const parseValue = () => {
        const parsedValue = [];
        parsedMarkdown.value = markdownArr.map((i) => {
            switch (true) {
                case (i.includes('**')):
                    parsedValue.push(i.replace('**', `<${(/^\*\*.*/g).test(i) ?  '' : '/'}strong>`));
                    break;
                case (i.includes('*')):
                    parsedValue.push(i.replace('*', `<${(/^\*.*/g).test(i) ?  '' : '/'}em>`));
                    break;
                case (i.includes('^')):
                    parsedValue.push(i.replace('^', `<${(/^\^.*/g).test(i) ?  'sup style="line-height: 0;font-size: 8px;margin-left:-1em"' : '/'}sup>`));
                    break;
                case (i.includes('<strike>') || i.includes('<s>')):
                    parsedValue.push(i.replace(/<s>|<strike>/g, `<s ${hasStyles}>`));
                    break;
                case (i.includes('<em>')):
                    parsedValue.push(i.replace('<em>', `<em ${hasStyles}>`));
                    break;
                case (i.includes('<del>') || i.includes('<delete>')):
                    parsedValue.push(i.replace(/<del>|<delete>/g, `<del ${hasStyles}>`));
                    break;
                case (i.includes('<i>')):
                    parsedValue.push(i.replace('<i>', `<i ${hasStyles}>`));
                    break;
                case (i.includes('\n')):
                    parsedValue.push(i.replace('\n', '<br>'));
                    break;
                case (regRegex.test(i)):
                    parsedValue.push(i.replace('®', '<span class="superscript">®</span>'));
                    break;
                case (tmRegex.test(i)):
                    parsedValue.push(i.replace('™', '<span class="trademark">™</span>'));
                    break;
                default:
                    parsedValue.push(`${i}`);
                    break;
            }
            return null;
        });
        return parsedValue.join(' ');
    };

    if (markdown !== undefined) {
        element.type = identifyElement();
    } else {
        mbpLogger.logWarning({ appName: process.env.npm_package_name, message: `parseMarkdown called but recieved ${markdown}` });
    }
    if (styles !== undefined && styles.length > 0) {
        hasStyles  = `style="${processCss(styles)}"`;
    }

    return `<${element.type} ${isStyle ? '' : hasStyles }>${parseValue()}</${element.type}>`;
}
